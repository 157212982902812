<template>
  <div>
    <vs-card id="users-view" v-if="!useStaffManagement">
      <div slot="header">
        <vs-row vs-align="center">
          <vs-col vs-w="12">
            <h3>{{ lang.users.generalSettings.title[languageSelected] }}</h3>
          </vs-col>
        </vs-row>
      </div>
      <div v-if="loaded">
        <vs-row vs-align="center">
          <vs-col class="w-full">
            <PasswordValidity />
          </vs-col>
        </vs-row>
      </div>
    </vs-card>
    <vs-card id="users-view">
      <div slot="header" v-if="isAuthAux('konecta.users.add')">
        <vs-row vs-align="center">
          <vs-col vs-w="8">
            <h3>{{ lang.users.manageUsers.title[languageSelected] }}</h3>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-w="4">
            <vs-button :to="{ path: '/users/add' }">{{
              lang.users.manageUsers.empty.addUsers[languageSelected]
            }}</vs-button>
          </vs-col>
        </vs-row>
      </div>
      <div v-if="loaded">
        <empty-users v-if="!hasUsers"></empty-users>

        <users-table
          v-if="hasUsers"
          :users="users"
          :max-items="maxItems"
          :on-edit="onUserEdit"
          :on-delete="onUserDelete"
        ></users-table>
      </div>
    </vs-card>
  </div>
</template>

<script>
import BotMakerService from '../../../services/botMaker.service'
import StaffManagementService from '../../../services/staffManagement.service'

import EmptyUsers from './components/EmptyUsers'
import UsersTable from '../bot-maker/add-bot/Users/UsersTable'
import PasswordValidity from '../configuration/users/PasswordValidity'
import { mapState } from 'vuex'
import { User } from '../../../models/User'

import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  name: 'Users',
  components: {
    EmptyUsers,
    UsersTable,
    PasswordValidity
  },
  mixins: [staffManagementMixin],
  data() {
    return {
      users: [],
      maxItems: 10,
      loaded: false,
      userToDelete: undefined
    }
  },
  computed: {
    ...mapState(['languageSelected', 'lang', 'session', 'useStaffManagement']),
    hasUsers() {
      return this.users.length > 0
    }
  },
  methods: {
    onUserEdit(userId) {
      this.$log.info(userId)
      this.$router.push('users/' + userId + '/edit')
    },
    async loadUsers() {
      this.$vs.loading()

      let staffRoles = []
      if (this.useStaffManagement) {
        try {
          staffRoles = (await StaffManagementService.getStaffRoles()).data
        } catch (ex) {
          this.$log.error(ex)
        }
      }

      BotMakerService.getUsers()
        .then(response => {
          this.users = response.data.map(user => {
            return new User(
              user.name,
              user.email,
              user.password,
              user.roles,
              user.company,
              user._id,
              undefined,
              undefined,
              staffRoles
                .filter(item => item.users.find(u => u._id === user._id))
                .map(item => ({ _id: item._id, name: item.name }))
            )
          })
        })
        .catch(error => {
          this.$log.error(error)
        })
        .finally(() => {
          this.$vs.loading.close()
          this.loaded = true
        })
    },
    deleteUser(userId) {
      this.$vs.loading()
      BotMakerService.deleteUser(userId)
        .then(() => {
          this.$vs.notify({
            title: this.lang.users.manageUsers.deleteSuccess.title[
              this.languageSelected
            ],
            text: this.lang.users.manageUsers.deleteSuccess.content[
              this.languageSelected
            ],
            color: 'success'
          })
          this.loadUsers()
        })
        .catch(error => {
          this.$log.error(error)
          this.$vs.notify({
            title: this.lang.botMaker.errorTitle[this.languageSelected],
            text: this.lang.botMaker.error[this.languageSelected],
            color: 'danger'
          })
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    onUserDelete(userId) {
      this.$log.info(userId)
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.lang.users.manageUsers.deleteUserPrompt.title[
          this.languageSelected
        ],
        text: this.lang.users.manageUsers.deleteUserPrompt.content[
          this.languageSelected
        ],
        acceptText: this.lang.botMaker.accept[this.languageSelected],
        cancelText: this.lang.botMaker.cancel[this.languageSelected],
        accept: () => {
          this.deleteUser(userId)
        }
      })
    }
  },
  mounted() {
    this.loadUsers()
  }
}
</script>
